import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../components/Layout'
import Prose from '../components/Prose'

import styles from './styles/Index.module.scss'

const Index = () => (
  <Layout title="planck — next-gen Supreme bot">
    <Prose>
      <p className={styles.Grey}>
        <em>
          the Planck time is considered by many physicists to be the shortest
          possible measurable time interval.
        </em>
      </p>
      <p>
        <em>planck</em> is a next-gen Supreme bot, built for power users. it is
        small, streamlined, and easy to use. but most of all, it’s powerful.{' '}
        <Link to="/about">read more &rarr;</Link>
      </p>
      <p>features:</p>
      <ul>
        <li>delivery & payment profiles</li>
        <li>task management & scheduling</li>
        <li>parallel task execution</li>
        <li>proxy support</li>
        <li>unlimited everything</li>
        <li>instant notifications</li>
      </ul>
      <p>
        if you’d like to use <em>planck</em>, please send an enquiry to{' '}
        <a
          href="mailto:planck@tdjs.tech?subject=I%20want%20to%20use%20planck"
          target="_blank"
          rel="noopener noreferrer"
        >
          planck@tdjs.tech
        </a>
        . we will be keeping numbers very limitied in order to provide
        first-class support.
      </p>
      <p>
        <a
          href="https://twitter.com/plancksoftware"
          rel="noopener noreferrer"
          target="_blank"
        >
          @plancksoftware
        </a>
      </p>
    </Prose>
  </Layout>
)

export default Index
