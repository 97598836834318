import React from 'react'
import Layout from '../components/Layout'
import Prose from '../components/Prose'

const About = () => (
  <Layout title="buy">
    <Prose>
      <strong>coming soon</strong>
      <img src="/collision.jpeg" alt="collision" draggable="false" />
    </Prose>
  </Layout>
)

export default About
