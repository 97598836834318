import React from 'react'
import Layout from '../components/Layout'
import Prose from '../components/Prose'

const About = () => (
  <Layout title="about">
    <Prose>
      <strong>about</strong>
      <p>
        <em>planck</em> is a next-gen Supreme bot, built for power users. it is
        small, streamlined, and easy to use. but most of all, it’s powerful.
      </p>
      <strong>guarantee</strong>
      <p>
        <em>planck</em> is an article of software designed to improve the
        probability of you being able to purchase an item of Supreme clothing by
        automating human actions. however, there is no gurantee that it will
        allow you to make a purchase.
      </p>
      <strong>why no graphical interface?</strong>
      <p>
        too many products spend more time on their UI than their actual
        function. our opinion is that function &gt; form, not vice versa.{' '}
        <em>planck</em> works &mdash; it doesn’t need some fancy interface to
        make up for the fact that it sucks.
      </p>
    </Prose>
  </Layout>
)

export default About
