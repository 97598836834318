import React from 'react'
import Layout from '../components/Layout'
import Prose from '../components/Prose'

const NotFound = () => (
  <Layout title="not found">
    <Prose>
      <strong>Error 404</strong>
      <p>Page not found.</p>
    </Prose>
  </Layout>
)

export default NotFound
