import React from 'react'
import Layout from '../components/Layout'
import Prose from '../components/Prose'

const Documentation = () => (
  <Layout title="documentation">
    <Prose>
      <strong>documentation</strong>
      <p>
        <em>planck</em> does not have a graphical user interface as you may
        expect. instead, you interact with <em>planck</em> via the command line.
        rather than exploring menus looking for an option, you simply tell{' '}
        <em>planck</em> what you want it to do. you don’t need to remember too
        many commands, interactive prompts guide you through most of the
        software.
      </p>
      <p>
        sure, this means that <em>planck</em> requires some technical aptitude
        to operate, but that’s never a bad thing.
      </p>
      <p>
        this documentation should answer any questions about how to use{' '}
        <em>planck</em>. if anything is unclear, or you still have questions,
        send an email to{' '}
        <a
          href="mailto:planck@tdjs.tech?subject=Support%20request"
          target="_blank"
          rel="noopener noreferrer"
        >
          planck@tdjs.tech
        </a>
        .
      </p>
      <p>contents:</p>
      <ul>
        <li>
          <a href="#dependencies">dependencies</a>
        </li>
        <li>
          <a href="#basic">basic</a>
        </li>
        <li>
          <a href="#delivery">delivery profiles</a>
        </li>
        <li>
          <a href="#payment">payment profiles</a>
        </li>
        <li>
          <a href="#tasks">tasks</a>
        </li>
        <li>
          <a href="#items">items</a>
        </li>
        <li>
          <a href="#settings">settings</a>
        </li>
      </ul>
      <hr />
      <strong id="dependencies">dependencies</strong>
      <p>
        <em>planck</em> requires that you have an instance of{' '}
        <a href="https://redis.io" target="_blank" rel="noopener noreferrer">
          redis
        </a>{' '}
        running. redis is an in-memory data store, which is used by{' '}
        <em>planck</em> for job scheduling. there are plenty of guides online
        that tell you how to get redis installed and running.
      </p>
      <hr />
      <strong id="basic">basic</strong>
      <p>the most basic operation is just:</p>
      <code>$ planck</code>
      <p>
        this will bring up a list of existing tasks and allow you to start them.
      </p>
      <hr />
      <strong id="delivery">delivery profiles</strong>
      <p>
        delivery profiles are used to store your shipping details. this includes
        name, email, phone, address etc.
      </p>
      <p>add a profile:</p>
      <code>$ planck delivery add</code>
      <p>remove a profile:</p>
      <code>$ planck delivery rm</code>
      <p>list all profiles:</p>
      <code>$ planck delivery ls</code>
      <p>edit a profile:</p>
      <code>$ planck delivery edit</code>
      <hr />
      <strong id="payment">payment profiles</strong>
      <p>
        payment profiles are used to store your payment details. this includes
        card type, number, expiry etc.
      </p>
      <p>add a profile:</p>
      <code>$ planck payment add</code>
      <p>remove a profile:</p>
      <code>$ planck payment rm</code>
      <p>list all profiles:</p>
      <code>$ planck payment ls</code>
      <p>edit. a profile:</p>
      <code>$ planck payment edit</code>
      <hr />
      <strong id="tasks">tasks</strong>
      <p>
        tasks are used to specify the items you want to buy, and the delivery
        and payment details you want to buy them with. they also store other
        information, such as proxy servers and delay intervals. when you start a
        task, <em>planck</em> will interact with the Supreme website and
        purchase you items.
      </p>
      <p>add a task:</p>
      <code>$ planck task add</code>
      <p>remove a task:</p>
      <code>$ planck task rm</code>
      <p>list all tasks:</p>
      <code>$ planck task ls</code>
      <p>edit a task:</p>
      <code>$ planck task edit</code>
      <p>select tasks to run:</p>
      <code>$ planck task start</code>
      <hr />
      <strong id="items">items</strong>
      <p>
        items are used to store the details of each item you want to buy. this
        includes category, size, +/- keywords etc.
      </p>
      <p>add an item:</p>
      <code>$ planck item add</code>
      <p>remove an item:</p>
      <code>$ planck item rm</code>
      <hr />
      <strong id="settings">settings</strong>
      <p>
        there are a number of other settings you can change in order to
        configure how <em>planck</em> operates.
      </p>
      <p>generally, these are set with:</p>
      <code>$ planck set [setting] [value]</code>
      <p>all settings and their possible values are listed below.</p>
      <ul>
        <li>
          region{' '}
          <span>&mdash; the region of the Supreme shop you will be using</span>
          <ul>
            <li>eu</li>
            <li>us</li>
          </ul>
        </li>
        <li>
          showBrowser{' '}
          <span>&mdash; when running a task, show the Chromium browser</span>
          <ul>
            <li>true</li>
            <li>false</li>
          </ul>
        </li>
        <li>
          showQueue{' '}
          <span>&mdash; when running a task, show the job queue UI</span>
          <ul>
            <li>true</li>
            <li>false</li>
          </ul>
        </li>
        <li>
          webhook{' '}
          <span>
            &mdash; the Discord webhook to post success notifications to
          </span>
          <ul>
            <li>any valid Discord webhook url</li>
          </ul>
        </li>
      </ul>
    </Prose>
  </Layout>
)

export default Documentation
