import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import styles from './Layout.module.scss'

class Layout extends React.PureComponent {
  render() {
    const { children, title } = this.props
    return (
      <div className={styles.Layout}>
        <Helmet>
          <title>
            {title ? `${title} — planck` : 'planck — next-gen Supreme bot'}
          </title>
          <meta
            property="og:title"
            content={
              title ? `${title} — planck` : 'planck — next-gen Supreme bot'
            }
          />
          <meta property="description" content="" />
          <meta property="og:description" content="" />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://planck.tdjs.tech/meta-1500.png"
          />
          <meta property="og:url" content="https://planck.tdjs.tech" />
        </Helmet>
        <div className={styles.PageContent}>
          <div className={styles.Nav}>
            <Link to="/" className={styles.Home}>
              planck
            </Link>
            <ul>
              <li>
                <Link to="/about">about</Link>
              </li>
              <li>
                <Link to="/docs">documentation</Link>
              </li>
              <li>
                <Link to="/buy">buy</Link>
              </li>
            </ul>
          </div>
          {children}
          <p className={styles.Footer}>
            &copy; {new Date().getFullYear()}{' '}
            <a
              href="https://tdjs.tech"
              rel="noopener noreferrer"
              target="_blank"
            >
              tdjs.tech
            </a>
          </p>
        </div>
      </div>
    )
  }
}

export default Layout
